import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2017 Mensajes" />
      <h1>2017 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2017, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio. <br />
      </p>
      <h2>Un Camino Simple - por Stephen Crisp</h2>
      <table
        width="641"
        height="20"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="508" height="20">
              Un Camino Simple
              <div align="center"></div>
            </td>
            <td width="49">
              <div align="center">
                <a href={asset('publicaciones/stephencrisp/camino_simple.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="44">
              <div align="center">
                <a href={asset('publicaciones/stephencrisp/camino_simple.epub')}>epub</a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a href={asset('publicaciones/stephencrisp/camino_simple.pdf')}>pdf</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>La Biografía de James Parnell</h2>
      <table
        width="641"
        height="20"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="508" height="20">
              La Biografía de James Parnell
              <div align="center"></div>
            </td>
            <td width="49">
              <div align="center">
                <a href={asset('publicaciones/jamesparnell/james_parnell.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="44">
              <div align="center">
                <a href={asset('publicaciones/jamesparnell/james_parnell.epub')}>epub</a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a href={asset('publicaciones/jamesparnell/james_parnell.pdf')}>pdf</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Los Escritos de Isaac Penington — Volumen 1</h2>
      <table
        width="641"
        height="165"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="470">Cap. 1 - Biografía de Isaac Penington</td>
            <td width="46">
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap01.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="41">
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap01.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td width="37">
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap01.pdf')}>pdf</a>
              </div>
            </td>
            <td width="47">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_01_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 2 - El Camino de la Vida y de la Muerte Hecho Manifesto</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap02.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap02.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap02.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_02_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 3 - Algunos Principios que Guían Hacia Afuera de la Apostasía</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap03.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap03.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap03.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_03_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 4 - El Hacha está Puesta a la Raíz del Árbol Corrupto</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap04.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap04.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap04.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_04_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 5 - En Busca de las Ovejas Esparcidas</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap05.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap05.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap05.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_05_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 6 - Babilonia la Grande - La Ciudad de Confusión</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap06.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap06.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap06.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_06_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 7 - Las Sagradas Escrituras</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap07.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap07.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap07.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_07_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 8 - La Autoridad y Gobierno que Cristo Excluyó de Su Iglesia</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap08.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap08.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap08.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_08_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 9 - El Nuevo Pacto del Evangelio y el Antiguo Pacto de la Ley</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap09.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap09.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap09.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_09_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 10 - La Oración, El Ministerio, La Sabiduría, Y el Reino</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap10.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap10.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap10.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_10_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 11 - Algunas Directrices para el Alma Anhelante</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap11.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap11.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap11.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_11_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Cap. 12 - Algunas Preguntas y Respuestas Para Que los Ojos Sean Abiertos
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap12.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap12.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap12.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_12_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 13 - La Suma y Sustancia de la Verdadera Religión</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap13.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap13.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap13.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_13_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 14 - Las Dos Semillas</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap14.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap14.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap14.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_14_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 15 - Cómo Discernir las Enseñanzas de Cristo</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap15.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap15.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap15.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_15_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 16a - Las Cartas de Isaac Penington - parte 1</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16a.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16a.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16a.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_16a_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 16b - Las Cartas de Isaac Penington - parte 2</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16b.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16b.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16b.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_16b_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 16c - Las Cartas de Isaac Penington - parte 3</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16c.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16c.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16c.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_16c_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>Cap. 16d - Las Cartas de Isaac Penington - parte 4</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16d.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16d.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/IP_Vol1_Cap16d.pdf')}>pdf</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/IP_Audiobook/IP_V1_Chap_16d_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
